<template>
  <AuthenticationCard>
    <form
      class="space-y-6"
      @submit.prevent="login()"
    >
      <div>
        <label
          for="email"
          class="block text-sm font-medium text-gray-700"
        >
          {{ $t('common.email_address') }}
        </label>
        <div class="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            v-model="user.email"
            class="login__input-box"
          />
        </div>
      </div>

      <div>
        <label
          for="password"
          class="block text-sm font-medium text-gray-700"
        >
          {{ $t('common.password') }}
        </label>
        <div class="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            v-model="user.password"
            class="login__input-box"
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div v-show="false">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="checkbox-main"
            />
            <label
              for="remember-me"
              class="ml-2 block text-sm text-gray-900"
            >
              {{ $t('common.remember_me') }}
            </label>
          </div>

        </div>

        <div class="text-sm">
          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ $t('common.forgot_password') }}
          </router-link>
        </div>
      </div>

      <div>
        <button
          type="submit"
          class="btn-main w-full"
        >
          <svg
            v-if="loading"
            class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 spinner"
            viewBox="0 0 50 50"
          >
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>

          {{ $t('common.sign_in') }}
        </button>
      </div>
    </form>
  </AuthenticationCard>
</template>
<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import AuthenticationCard from '../../components/@globals/helpers/AuthenticationCard';
export default {
  name: 'Login',
  components: {
    AuthenticationCard,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const user = reactive({
      email: '',
      password: '',
    });

    async function login() {
      loading.value = true;
      try {
        await store.dispatch('login', user);
        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    }
    return {
      login,
      loading,
      user,
    };
  },
};
</script>
<style lang="scss" scoped>
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 8px;
  right: 15px;
  width: 20px;
  height: 20px;

  & .path {
    stroke: hsl(210, 70%, 75%);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
