<template>
  <AuthenticationCard>
    <div class="mb-4 text-sm text-gray-600">
      Forgot your password? No problem. Just let us know your email address and
      we will email you a password reset link that will allow you to choose a
      new one.
    </div>

    <div
      v-if="status"
      class="mb-4 font-medium text-sm"
      :class="[form.successful ? 'text-green-600' : 'text-red-600']"
    >
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div>
        <Label for="email" value="Email" />
        <Input
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          required
          autofocus
        />
        <InputError :message="form.errors.get('email')" class="mt-2" />
      </div>

      <div class="flex items-center justify-end mt-4">
        <Button :class="{ 'opacity-25': form.busy }" :disabled="form.busy">
          Email Password Reset Link
        </Button>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script>
import AuthenticationCard from '../../components/@globals/helpers/AuthenticationCard';
import Button from '../../components/@globals/helpers/Button';
import Label from '../../components/@globals/helpers/Label';
import Input from '../../components/@globals/helpers/Input';
import InputError from '../../components/@globals/helpers/InputError';
import { ref } from 'vue';
import Form from 'vform';
import AuthService from '@/service/oauth/oauth.service.js';
export default {
  name: 'ForgotPassword',
  components: {
    Input,
    InputError,
    Button,
    Label,
    AuthenticationCard,
  },
  setup() {
    const status = ref(null);
    const form = ref(
      Form.make({
        email: '',
      })
    );

    function submit() {
      AuthService.forgotPassword(form.value)
        .then((response) => {
          status.value = response.data.message;
        })
        .catch((error) => {
          status.value = error.response.data.message;
        });
    }
    return {
      submit,
      form,
      status,
    };
  },
};
</script>
